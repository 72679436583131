import React, { useState, useEffect } from 'react'

const Loader = () => (
  <div className="tc">
    <h1>Objednávka se odesílá</h1>
    <svg
      version="1.1"
      id="loader-1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={40}
      height={40}
      viewBox="0 0 50 50"
      style={{ enableBackground: 'new 0 0 50 50' }}
    >
      <path
        fill="#00449e"
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
);
const statusComponent = {
  sending: Loader,
  send: () => (
    <>
      <div className="tc">
        <h1>Objednávka byla úspěšně odeslána</h1>
      </div>
      <div className="tc">
        <a
          className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
          href="/"
        >
          Pokračovat v nákupu
        </a>
      </div>
    </>
  ),
  error: () => (
    <>
      <div className="tc">
        <h1>Objednávku se nepodařilo odeslat</h1>
      </div>
      <div className="tc">
        <a
          className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
          href="/"
        >
          Pokračovat v nákupu
        </a>
      </div>
    </>
  ),
};

const CartSend = () => {
  const [status, setStatus] = useState('sending');
  const Component = statusComponent[status];

  useEffect(() => {
    fetch('/api/cart/send', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({}),
    })
      .then(() => {
        setStatus('send')
      })
      .catch(e => {
        console.error(e);
        setStatus('error')
      })
  }, []);
  return <Component />
};

export default CartSend

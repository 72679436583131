import React from 'react'

import Layout from '../../layouts/layout'
import CartSend from '../../components/ShopingCart/CartSend'

const ShopingCartSendPage = () => (
  <Layout>
    {() => (
      <main className="w-100 pv2">
        <CartSend />
      </main>
    )}
  </Layout>
)

export default ShopingCartSendPage
